import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from "@tippyjs/react";
import moment from "moment-mini";
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import "tippy.js/dist/tippy.css";
import { TP_REDEMPTION_RATE } from "../../../@types/Constants";
import { ITaxItem } from "../../../@types/ITaxItem";
import { customFormatDate, parseSearchDate } from "../../../services/dates";
import { priceFormatter } from "../../../utils/helpers";
import { FreeStay } from "../../common/CheckoutTotal/CheckoutTotalHelpers";
import { Container, StyledRow, Tooltip } from "./NewCurrentTotalHelpers";
import { INewCurrentTotalProps } from "./NewCurrentTotalProps";

const NewCurrentTotal: React.FC<INewCurrentTotalProps> = ({
  rooms,
  reservation,
  className,
}) => {
  const checkInDate = parseSearchDate(customFormatDate(reservation.start));
  const checkOutDate = parseSearchDate(customFormatDate(reservation.end));
  const [showPriceDetails, setShowPriceDetails] = useState(false);
  const checkoutObj = useSelector(
    (state: any) => state.editReservationCheckout
  );
  const roomRate = reservation.roomRates[0];
  const detailsIcon = !showPriceDetails ? "angle-down" : "angle-up";
  const optionsTaxObject: any = {};
  const [taxesTotal, setTaxesTotal] = useState(0);
  const [feesTotal, setFeesTotal] = useState(0);
  const servicePrice = (roomRate && roomRate.upgrades) || 0;
  const total = roomRate && parseFloat(roomRate.total);
  const [subTotal, setSubTotal] = useState(
    roomRate && parseFloat(roomRate.subTotal)
  );
  const nightsInDays: number = moment(checkOutDate, "YYYY-MM-DD").diff(
    moment(checkInDate, "YYYY-MM-DD"),
    "days"
  );
  const nights = nightsInDays > 1 ? `${nightsInDays} Nights` : `1 Night`;
  const currency =
    rooms && rooms.length && rooms[0].rate ? rooms[0].rate?.currencyCode : "";
  const [updatedServices, setUpdatedServices] = useState([]);
  const [updatedTaxes, setUpdatedTaxes] = useState([]);

  const handleShowPriceDetails = () => {
    setShowPriceDetails(!showPriceDetails);
  };

  useEffect(() => {
    // Combining prices for multiple responses for same add ons

    if (reservation.services) {
      const _updatedServices: any = [];
      let roomSubTotal = roomRate && parseFloat(roomRate.subTotal);
      reservation.services.map((service: any) => {
        const serviceCount = parseInt(service.quantity);
        let isExist = false;
        _updatedServices.map((uService: any) => {
          if (uService.ServiceInventoryCode === service.serviceInventoryCode) {
            isExist = true;
            uService.Price =
              uService.Price + +service.price.rate * serviceCount;
            uService.Taxes =
              uService.Taxes + +service.price.taxes * serviceCount;
            roomSubTotal += +service.price.rate;
          }
        });
        if (!isExist) {
          const _service = {
            Quantity: service.quantity,
            Description: service.description,
            ServiceInventoryCode: service.serviceInventoryCode,
            san: service.san,
            Price: +service.price.rate * serviceCount,
            Taxes: +service.price.taxes * serviceCount,
          };
          roomSubTotal += _service.Price;
          _updatedServices.push(_service);
        }
      });
      setUpdatedServices(_updatedServices);
      setSubTotal(roomSubTotal);
    }
  }, [reservation]);

  useEffect(() => {
    // Combining taxes for multiple responses for same add ons
    let taxes = 0;
    let fees = 0;
    const taxItemsWise: Array<ITaxItem> = [];
    roomRate?.taxItems &&
      roomRate.taxItems.length &&
      roomRate.taxItems.forEach((taxItem: ITaxItem) => {
        let taxItemAmount = parseFloat(taxItem.amount);
        if (taxItem.chargeFrequency == 1) {
          // per night
          const numOfNights =
            moment(taxItem.expireDate).diff(
              moment(taxItem.effectiveDate),
              "days"
            ) + 1;
          taxItemAmount = taxItemAmount * numOfNights;
        }
        if (taxItemAmount) {
          taxes += taxItemAmount;
        }

        const existingItemindex = taxItemsWise.findIndex(
          (item) => item.name === taxItem.name
        );
        if (existingItemindex > -1) {
          taxItemsWise[existingItemindex]["amount"] =
            taxItemsWise[existingItemindex]["amount"] + taxItemAmount;
        } else {
          taxItemsWise.push({ ...taxItem, amount: taxItemAmount });
        }
      });

    roomRate?.feeItems &&
      roomRate?.feeItems.length &&
      roomRate?.feeItems.forEach((feeItem: ITaxItem) => {
        let feeItemAmount = parseFloat(feeItem.amount);
        if (feeItem.chargeFrequency == 1) {
          // per night
          const numOfNights =
            moment(feeItem.expireDate).diff(
              moment(feeItem.effectiveDate),
              "days"
            ) + 1;
          feeItemAmount = feeItemAmount * numOfNights;
        }
        if (feeItemAmount) {
          fees += feeItemAmount;
        }
      });

    servicePrice > 0 &&
      updatedServices.length &&
      updatedServices.forEach((service: any) => {
        taxes += parseFloat(service["Taxes"]);
        const taxItem: any = {
          name: service.Description,
          description: service.Description,
          amount: service.Taxes,
          chargeFrequency: 1,
        };
        taxItemsWise.push(taxItem);
      });
    setUpdatedTaxes(taxItemsWise);
    setTaxesTotal(taxes);
    setFeesTotal(fees);
  }, [updatedServices]);

  let totalPointsforOldReservation;
  const isRedemptionRate = TP_REDEMPTION_RATE.includes(
    reservation?.ratePlanCode
  );
  if (isRedemptionRate) {
    const pointsRequiredPerDay =
      checkoutObj?.redemptionItem &&
      checkoutObj?.redemptionItem?.currencyRequired
        ? parseInt(checkoutObj?.redemptionItem?.currencyRequired)
        : 0;
    const numOfNights = moment(reservation.end).diff(
      moment(reservation.start),
      "days"
    );
    totalPointsforOldReservation = pointsRequiredPerDay * numOfNights;
  }

  const getRoomFeePrice = (roomRate: any) => {
    let roomFeesTotal = 0;
    const feesItemsWise: Array<ITaxItem> = [];
    roomRate?.feeItems &&
      roomRate?.feeItems.forEach((feeItem: ITaxItem) => {
        let feeItemAmount = parseFloat(feeItem.amount);
        if (feeItem.chargeFrequency == 1) {
          // per night
          const numOfNights =
            moment(feeItem.expireDate).diff(
              moment(feeItem.effectiveDate),
              "days"
            ) + 1;
          feeItemAmount = feeItemAmount * numOfNights;
        }
        roomFeesTotal += feeItemAmount;
        const existingItemindex = feesItemsWise.findIndex(
          (item) => item.name === feeItem.name
        );
        if (existingItemindex > -1) {
          feesItemsWise[existingItemindex]["amount"] =
            feesItemsWise[existingItemindex]["amount"] + feeItemAmount;
        } else {
          feesItemsWise.push({ ...feeItem, amount: feeItemAmount });
        }
      });
    return { roomFeesTotal, feesItemsWise };
  };

  return (
    <Container className={className}>
      <StyledRow className="pt-3">
        {isRedemptionRate ? <FreeStay /> : null}
        <Col className="details px-0">
          <div className="heading" id="current-total-heading">
            Current Total
          </div>
        </Col>
        <Col
          className="text-end total"
          lg="auto"
          xs="auto"
          id="current-total-cart-total"
        >
          {isRedemptionRate ? (
            <span>
              {totalPointsforOldReservation
                ? `${totalPointsforOldReservation.toLocaleString()} pts`
                : "FREE"}
            </span>
          ) : (
            priceFormatter(total, 2, currency)
          )}
        </Col>
      </StyledRow>
      <StyledRow className="flex-row-reverse">
        <Col xs="auto" className="text-end breakdown mb-2 mb-lg-0">
          {isRedemptionRate && (
            <div id="total-trip-cost">
              Total Trip Cost: {priceFormatter(0, 2, currency)}
            </div>
          )}
          <div id="current-total-number-of-stays">
            {nights} {rooms.length > 1 ? `- ${rooms.length} Rooms` : ""}:{" "}
            {isRedemptionRate
              ? priceFormatter(0, 2, currency)
              : priceFormatter(subTotal, 2, currency)}
          </div>
          <div id="current-total-taxes-for-stays">
            Fees :{" "}
            {isRedemptionRate
              ? priceFormatter(0, 2, currency)
              : priceFormatter(feesTotal, 2, currency)}
          </div>
          <div id="current-total-taxes-for-stays">
            Taxes :{" "}
            {isRedemptionRate
              ? priceFormatter(0, 2, currency)
              : priceFormatter(taxesTotal, 2, currency)}
          </div>
        </Col>
      </StyledRow>
      {showPriceDetails && (
        <StyledRow className={`pb-3 pb-lg-0`}>
          <Col className="px-0 d-flex align-items-end">
            <div className="breakdown mt-1 w-100">
              <div className="mb-n3 mb-lg-0">
                {rooms.map((room: any, idx: number) => {
                  if (reservation.roomRates.length > 0) {
                    // const servicePrice = getRoomServiceSubTotal(room);
                    const { roomFeesTotal, feesItemsWise } = getRoomFeePrice(
                      reservation.roomRates[0]
                    );
                    return (
                      <div key={`room-price${idx}`}>
                        <div
                          className="fw-bold mt-2"
                          id={`current-total-room-${idx + 1}`}
                        >
                          Room {idx + 1}
                        </div>
                        <Row>
                          <Col id="no-of-nights">{nights}</Col>
                          <Col
                            id="cost-of-stays"
                            style={{
                              display: "flex",
                              flexDirection: "row-reverse",
                            }}
                          >
                            {isRedemptionRate
                              ? priceFormatter(0, 2, currency)
                              : priceFormatter(roomRate.subTotal, 2, currency)}
                          </Col>
                        </Row>
                        {servicePrice > 0 &&
                          updatedServices.map(function (
                            service: any,
                            index: number
                          ) {
                            return (
                              <Row key={`service-${index}`}>
                                <Col>
                                  {service["Description"]}{" "}
                                  {service["Description"] && (
                                    <Tippy
                                      content={service["Description"]}
                                      theme="bootstrap"
                                    >
                                      <Tooltip>
                                        <FontAwesomeIcon
                                          icon={"info-circle"}
                                          className="icon"
                                        />
                                      </Tooltip>
                                    </Tippy>
                                  )}
                                </Col>
                                <Col
                                  style={{
                                    display: "flex",
                                    flexDirection: "row-reverse",
                                  }}
                                >
                                  {priceFormatter(
                                    service["Price"],
                                    2,
                                    currency
                                  )}
                                </Col>
                              </Row>
                            );
                          })}
                        {roomFeesTotal > 0 &&
                          feesItemsWise.map((tItem, idx) => {
                            if (tItem.amount) {
                              return (
                                <div key={`fee-item${idx}`}>
                                  <Row>
                                    <Col>
                                      {tItem.name}{" "}
                                      {tItem.description && (
                                        <Tippy
                                          content={tItem.description}
                                          theme="bootstrap"
                                        >
                                          <Tooltip>
                                            <FontAwesomeIcon
                                              icon={"info-circle"}
                                              className="icon"
                                            />
                                          </Tooltip>
                                        </Tippy>
                                      )}
                                    </Col>
                                    <Col
                                      style={{
                                        display: "flex",
                                        flexDirection: "row-reverse",
                                      }}
                                    >
                                      {isRedemptionRate
                                        ? priceFormatter(0, 2, currency)
                                        : priceFormatter(
                                            parseFloat(tItem.amount),
                                            2,
                                            currency
                                          )}
                                    </Col>
                                  </Row>
                                </div>
                              );
                            }
                            return null;
                          })}
                      </div>
                    );
                  }
                  return null;
                })}
                <div className="my-3">
                  <span id="taxes-and-fees" className="fw-bold">
                    Taxes
                  </span>
                  {updatedTaxes.map((tItem, idx) => {
                    if (tItem.amount) {
                      return (
                        <div
                          key={`tax-item${idx}`}
                          id={`current-total-tax-item${idx + 1}`}
                        >
                          <Row>
                            <Col id="tax-item-name">
                              {tItem.Name || tItem.name}{" "}
                              {(tItem.Description || tItem.description) && (
                                <Tippy
                                  content={
                                    tItem.Description || tItem.description
                                  }
                                  theme="bootstrap"
                                >
                                  <Tooltip id={"tax-item-description"}>
                                    <FontAwesomeIcon
                                      icon={"info-circle"}
                                      className="icon"
                                    />
                                  </Tooltip>
                                </Tippy>
                              )}
                            </Col>
                            <Col
                              id="tax-item-cost"
                              style={{
                                display: "flex",
                                flexDirection: "row-reverse",
                              }}
                            >
                              {priceFormatter(
                                parseFloat(tItem.amount),
                                2,
                                currency
                              )}
                            </Col>
                          </Row>
                        </div>
                      );
                    }
                    return null;
                  })}
                  {Object.keys(optionsTaxObject).length !== 0 &&
                    Object.entries(optionsTaxObject).map((item) => {
                      return (
                        <div key={`tax-item-${item[0]}`}>
                          <Row>
                            <Col>{item[1].Title} Tax</Col>
                            <Col
                              style={{
                                display: "flex",
                                flexDirection: "row-reverse",
                              }}
                            >
                              {priceFormatter(item[1].Tax, 2, currency)}
                            </Col>
                          </Row>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </Col>
        </StyledRow>
      )}
      <StyledRow>
        <Col className="p-0">
          <Button
            variant="link"
            className="mt-2 p-0 text-uppercase price-btn"
            onClick={handleShowPriceDetails}
            id={`expand-price-details-button`}
          >
            {!showPriceDetails ? "Expand Price Details" : "HIDE Price Details"}
            <FontAwesomeIcon
              style={{ pointerEvents: "none" }}
              icon={detailsIcon}
              className="ms-1"
            />
          </Button>
        </Col>
      </StyledRow>
    </Container>
  );
};

export default NewCurrentTotal;
