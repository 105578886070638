import * as React from "react";
import { Alert, Button, Col, Row } from "react-bootstrap";
import NewReservationDetails from "../NewReservationDetails/NewReservationDetails";
import useMobileDetect from "../../../hooks/useMobileDetect";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import moment from "moment-mini";
import {
  customFormatDate,
  formatDate,
  parseSearchDate,
  resParseDateFormat,
} from "../../../services/dates";
import { navigate } from "gatsby";
import CheckoutSpecialRequest from "../../common/CheckoutSpecialRequest/CheckoutSpecialRequest";
import { useState } from "react";
import CheckoutAgreement from "../../common/CheckoutAgreement/CheckoutAgreement";
import AddOns from "../AddOns/AddOns";
import {
  createEditReservationPayload,
  fetchReservationDetails,
  updateReservationCBF,
} from "../../../services/crs";
import { useWebFramed } from "../../../hooks/useWebFramed";
import {
  formatFramedWebReservationDetails,
  PostMessage,
} from "../../../utils/webFramed";
import { Constants, Edit_Reservation_Success } from "../../../@types/Constants";
import ErrorMessageAlert from "../../common/ErrorMessageAlert/ErrorMessageAlert";
import { DarkBackground } from "../../../pageHelpers/EditReservation/EditReservationHelpers";
import LoadingOverlay from "react-loading-overlay";
import GuestInfo from "./GuestInfo";
import CreditCardForm from "../../common/CreditCardForm/CreditCardForm";
import {
  addOns,
  specialRequestText,
  updateButton,
} from "./SelectAddOns.module.scss";
import NewCurrentTotal from "../NewCurrentTotal/NewCurrentTotal";
import NewUpdatedTotal from "../NewUpdatedTotal/NewUpdatedTotal";
import CheckCheckoutRoomsAvailability from "../../common/CheckCheckoutRoomsAvailability/CheckCheckoutRoomsAvailability";
import { guestServiceProvider } from "../../../services/ServiceProvider";
import { setEditReservationCheckout } from "../../../redux/slices/Checkout/editReservationCheckout";
import { checkRedemptionRateInCheckout } from "../../../services/redemption";
import IRoom from "../../../@types/IRoom";
import { convertArrayToObject } from "../../../utils/helpers";
import { editResConfirmationGTMDataLayer } from "../../../utils/datalayers";

export const SelectAddOns: React.FC<any> = (props) => {
  const isMobileOnly = useMobileDetect();
  const isWebFramed = useWebFramed();
  const dispatch = useAppDispatch();
  const [newCCToken, _setNewCCToken] = useState(null);
  const [tokenxData, setTokenxData] = useState(null);
  const checkout: { [x: string | number]: any } = useAppSelector(
    (state) => state.editReservationCheckout
  );
  const guestInfo =
    checkout.originalReservation.reservation.guests &&
    checkout.originalReservation.reservation.guests[0];
  const [specialRequest, setSpecialRequest] = useState("");
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [showError, setShowError] = useState(false);
  const [isUpdatingReservation, setIsUpdatingReservation] = useState(false);
  const [overlayMessage, setOverlayMessage] = useState("");
  const [reservationTimeout, setReservationTimeout] =
    useState<NodeJS.Timeout | null>(null);
  const confirmationID = checkout.originalReservation.confirmationID;
  const [checkRoomsAvailability, setCheckRoomsAvailability] = useState(false);
  const [allRoomsAvailable, setAllRoomsAvailable] = useState(false);
  const [showAddons, setShowAddons] = useState(true);

  const isMember = checkout.isMember;

  const navigateToReservationPage = () => {
    navigate("/reservation", {
      state: {
        reservation: {
          crs_reservation_id: checkout.originalReservation.confirmationID, //reservation.ID,
          arrival: moment(
            customFormatDate(checkout.originalReservation.reservation.start),
            "YYYY-MM-DD"
          ).format(resParseDateFormat),
          departure: moment(
            customFormatDate(checkout.originalReservation.reservation.end),
            "YYYY-MM-DD"
          ).format(resParseDateFormat),
          details: checkout.originalReservation.reservation, //{...reservation, HotelCode: '75577'}
        },
        lastName: checkout.originalReservation.reservation.guests[0].surname,
      },
    });
  };

  const handleAllRoomsAvailable = () => {
    setAllRoomsAvailable(true);
    setCheckRoomsAvailability(false);
  };
  const handleSelectedRoomsSoldOut = (rooms: Array<any>) => {
    setIsUpdatingReservation(false);
    setCheckRoomsAvailability(false);
    props.onSelectedRoomsSoldOut(rooms);
  };

  const clearError = () => {
    setErrorMsg("");
    setShowError(false);
  };

  const handleClearError = () => {
    setErrorMsg("");
    setShowError(false);
  };

  React.useEffect(() => {
    const isRedemptionRate = checkRedemptionRateInCheckout(checkout);
    const checkoutRooms: Array<IRoom> = checkout.Rooms
      ? Object.values(checkout.Rooms)
      : [];
    if (isRedemptionRate) {
      setShowAddons(false);
      let room = checkoutRooms[0];
      room = { ...room, services: null };
      const newRooms = [room];
      const newCheckout = {
        ...checkout,
        Rooms: convertArrayToObject(newRooms, "id"),
      };
      dispatch(setEditReservationCheckout(newCheckout));
    }
  }, []);

  React.useEffect(() => {
    if (allRoomsAvailable) {
      completeReservation(newCCToken);
    }
  }, [allRoomsAvailable]);

  React.useEffect(() => {
    if (tokenxData && tokenxData.type === "valid") {
      completeReservation(tokenxData);
    }
  }, [tokenxData]);

  const onRequestFailure = (message: string) => {
    const errorMetaData = confirmationID;
    const errorMsg = (
      <ErrorMessageAlert
        errorType={Constants.ERRORSTYPE.RESERVATION}
        errorSubType={Constants.ERRORSSUBTYPE.RESRVATION.UPDATE}
        message={`${message} -- ${errorMetaData}`}
      >
        Your reservation could not be updated. <span>{message}</span>
      </ErrorMessageAlert>
    );
    setErrorMsg(errorMsg);
    setShowError(true);
    const element = document.getElementById("update-reservation-error-message");
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  const handleChangeSpecialRequest = (e: any) => {
    setSpecialRequest(e.target.value);
  };

  const handleCheckAgreeTerms = () => {
    setTermsAgreed(!termsAgreed);
  };

  const handleTokenXResponse = (data: any) => {
    if (data && data.type === "valid") {
      setTokenxData(data);
    } else {
      setIsUpdatingReservation(false);
    }
  };

  const handleReserveYourStay = () => {
    clearError();
    setIsUpdatingReservation(true);
    setOverlayMessage("Verifying credit card...");
    // Dev Comments - Old Edit Reservation Logic
    // if (!checkout || !checkout.Payment) {
    //   onRequestFailure(
    //     "Oops! something went wrong. Please reload the page or try again later."
    //   );
    //   setIsUpdatingReservation(false);
    // } else {
    //   if (!checkout.Payment.ccToken) {
    //     setOverlayMessage("Verifying credit card...");
    //     if (typeof window !== "undefined") {
    //       (window as any).mycheckWallet.getCardToken().then(
    //         function (token: any) {
    //           setNewCCToken(token);
    //           completeReservation(token);
    //           //setCheckRoomsAvailability(true);
    //         },
    //         function (error: any) {
    //           const paymentSection = document.getElementById(
    //             "checkoutPaymentSection"
    //           );
    //           paymentSection && paymentSection.scrollIntoView();

    //           onRequestFailure(error.message);
    //           setIsUpdatingReservation(false);
    //         }
    //       );
    //     }
    //   } else {
    //     completeReservation(checkout.Payment.ccToken);
    //   }
    // }
  };

  const completeReservation = async (ccDetails?: any) => {
    setOverlayMessage("Updating reservation...");
    const timeout = setTimeout(() => {
      setOverlayMessage("It's taking longer than expected...");
    }, 30000);

    setReservationTimeout(timeout);

    const checkoutRooms = checkout.Rooms ? Object.values(checkout.Rooms) : [];
    if (!checkout.isMember && checkoutRooms[0]?.rate?.rateType === "member") {
      try {
        // if (process.env.GATSBY_IS_CDP_ENABLED !== "ENABLE") {
        //   enrollMember({
        //     firstname: checkout.GuestDetails.firstName,
        //     lastname: checkout.GuestDetails.lastName,
        //     email: checkout.GuestDetails.email,
        //     password: "Default1",
        //     synxisHotelId: checkout.HotelCode,
        //   });
        // } else {
        //   signUpCDP({
        //     firstname: checkout.GuestDetails.firstName,
        //     lastname: checkout.GuestDetails.lastName,
        //     email: checkout.GuestDetails.email,
        //   });
        // }
        const guestService = await guestServiceProvider();
        const response = await guestService.signUp(
          checkout.GuestDetails.email,
          checkout.GuestDetails.firstName,
          checkout.GuestDetails.lastName
        );
      } catch (err: any) {
        // eslint-disable-next-line no-console
        console.error("Error while member signing up.", err.message);
      }
    }
    let checkoutJSON = checkout;
    // if (ccToken) {
    //   checkoutJSON = {
    //     ...checkoutJSON,
    //     Payment: { ...checkout.Payment, ccToken: ccToken },
    //   };
    // }

    if (specialRequest) {
      checkoutJSON = {
        ...checkoutJSON,
        SpecialRequests: specialRequest,
      };
    }
    let totalPointsforReservation;
    const isRedemptionRate = checkRedemptionRateInCheckout(checkoutJSON);
    if (isRedemptionRate) {
      const pointsRequiredPerDay =
        checkoutJSON?.redemptionItem &&
        checkoutJSON?.redemptionItem?.currencyRequired
          ? parseInt(checkoutJSON?.redemptionItem?.currencyRequired)
          : 0;
      const numOfNights = moment(checkoutJSON.End).diff(
        moment(checkoutJSON.Start),
        "days"
      );
      totalPointsforReservation = pointsRequiredPerDay * numOfNights;
    }
    // const request = createResRequestJSON(checkoutJSON);
    const request = createEditReservationPayload(
      checkoutJSON,
      ccDetails.response
    );
    const confirmation = await updateReservationCBF(
      request,
      checkoutJSON.originalReservation.confirmationID
    );
    // Dev Comments - commenting updateReservation call as GAPI team is still working on it.
    if (
      confirmation &&
      confirmation.reservationConfirmationId &&
      confirmation.success
    ) {
      const getReservationDetails = await fetchReservationDetails({
        resId: confirmation.reservationConfirmationId,
        lastName: checkoutJSON?.GuestDetails.lastName,
      });
      const reservationDetails = getReservationDetails.value;
      const confirmedReservations = [
        {
          crs_reservation_id: reservationDetails.iD,
          arrival: moment(reservationDetails.start, "YYYY-MM-DD").format(
            resParseDateFormat
          ),
          departure: moment(reservationDetails.end, "YYYY-MM-DD").format(
            resParseDateFormat
          ),
          details: {
            ...reservationDetails,
            Start: reservationDetails.start,
            End: reservationDetails.end,
            Crs: "synxis",
          },
          lastName: reservationDetails.guests[0]?.surname || guestInfo.surname,
          requiredPoints: totalPointsforReservation,
        },
      ];
      if (isWebFramed) {
        const framedWebResDetails = await formatFramedWebReservationDetails(
          reservationDetails
        );
        const dateFormat = "YYYY-MM-DD";
        const dateConcatString = "T00:00:00.000Z";
        PostMessage({
          type: Edit_Reservation_Success,
          payload: {
            roomType: reservationDetails.roomDescription.name,
            roomRate: reservationDetails.roomRates[0].total,
            startDate: `${moment(reservationDetails.start).format(
              dateFormat
            )}${dateConcatString}`, // We are parsing start date into a specif format as per mobile team requested. example: 2023-11-01T00:00:00.000Z
            endDate: `${moment(reservationDetails.end).format(
              dateFormat
            )}${dateConcatString}`, // We are parsing end date into a specif format as per mobile team requested. example: 2023-11-01T00:00:00.000Z
            checkInTime: reservationDetails.policies.checkInTime?.toUpperCase(),
            checkOutTime:
              reservationDetails.policies.checkOutTime?.toUpperCase(),
            cancelPolicies: {
              policyCode: reservationDetails.cancelPolicies?.policyCode || "",
              description: reservationDetails.cancelPolicies?.description || "",
            },
            details: { ...framedWebResDetails, Crs: "synxis" },
          },
        });
      } else {
        try {
          const hotelInfo = checkout.originalReservation.hotel;

          const { brand_id } = hotelInfo?.relationships ?? {};
          const brand = {
            code: brand_id?.brand_code,
            name: brand_id?.name,
          };

          const checkInDate = parseSearchDate(checkout.Start);
          const checkOutDate = parseSearchDate(checkout.End);

          const rooms = Object.values(checkout.Rooms) ?? [];
          const [firstRoom]: any = rooms;

          const rate = firstRoom?.rate ?? {};
          const room = firstRoom?.room ?? {};

          const details = {
            checkinDate: checkInDate
              ? formatDate(checkInDate, "YYYY-MM-DD")
              : "",
            checkoutDate: checkOutDate
              ? formatDate(checkOutDate, "YYYY-MM-DD")
              : "",
            lengthOfStay: rate.roomRateNightly?.length ?? "-",
            adults: firstRoom?.adults,
            children: firstRoom?.children,
            totalGuests: (firstRoom?.adults ?? 0) + (firstRoom?.children ?? 0),
            rateName: rate.name,
            rateCode: rate.RateCode,
            lowestRate: room.LowestRate,
            isonlyRate: room.IsonlyRate,
            rateCount: room.RateCount,
          };

          editResConfirmationGTMDataLayer(
            confirmedReservations,
            hotelInfo,
            brand,
            details,
            "update"
          );
        } catch (e) {
          console.error(e);
        }

        navigate("/reservation-confirmation", {
          state: {
            reservations: confirmedReservations,
            confirmationType: "update",
          },
        });
      }
    } else {
      onRequestFailure(confirmation && "Something went wrong.");
    }

    if (reservationTimeout) {
      clearTimeout(reservationTimeout);
      setReservationTimeout(null);
    }

    setIsUpdatingReservation(false);
  };

  return (
    <Row className={`mt-lg-4 mt-4 pt-2 selectAddonsContainer`}>
      {checkRoomsAvailability && (
        <CheckCheckoutRoomsAvailability
          onAllRoomsAvailable={handleAllRoomsAvailable}
          onSelectedRoomsSoldOut={handleSelectedRoomsSoldOut}
        />
      )}
      {/* <Col lg={7}><NewEditOffers /></Col> */}
      <Col lg={7}>
        {showError && errorMsg && (
          <Row className="px-lg-5 mx-lg-5">
            <Col>
              <Alert
                id="update-reservation-error-message"
                variant="danger"
                className="text-center"
                dismissible
                onClose={handleClearError}
              >
                {errorMsg}
              </Alert>
            </Col>
          </Row>
        )}
        {showAddons && (
          <div className={addOns}>
            <AddOns />
          </div>
        )}
        <Row className={`mt-5 mb-4 ${specialRequestText}`}>
          <Col>
            <CheckoutSpecialRequest
              setSpecialRequest={handleChangeSpecialRequest}
            />
          </Col>
        </Row>
        <Row className="mt-5 mb-4">
          <Col>
            <div id="Guest Info">
              <GuestInfo
                guestDetails={checkout.GuestDetails}
                isMember={isMember}
              />
            </div>
          </Col>
        </Row>
        <Row className="mt-5 mb-4">
          <Col>
            <div id="checkoutPaymentSection">
              {/* <EditCheckoutPayment
                className="mt-2"
                email={guestInfo.email}
                handleSetScroll={props.handleSetScroll}
              /> */}
              <CreditCardForm
                reservationButtonID={"update-reservation-button"}
                handleTokenXResponse={handleTokenXResponse}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <NewCurrentTotal
            className={"pl-0.75 pr-0.75 pb-2 border-top border-bottom"}
            rooms={Object.values(checkout.Rooms)}
            reservation={checkout.originalReservation.reservation}
          />
          <NewUpdatedTotal className={"pl-0.75 pr-0.75 pb-2 border-bottom"} />
        </Row>
        <Row className="my-4">
          <Col>
            <CheckoutAgreement
              termsAgreed={termsAgreed}
              setAgreeTerms={handleCheckAgreeTerms}
              editCheckout={checkout}
              pageType="editReservation"
            />
          </Col>
        </Row>
      </Col>

      {!isMobileOnly && (
        <Col lg={{ span: 4, offset: 1 }}>
          <NewReservationDetails
            reservation={checkout.originalReservation.reservation}
            hotel={checkout.originalReservation.hotel}
          />
        </Col>
      )}

      <Col lg={7} className="mt-5">
        <div className={updateButton}>
          <Button
            type="submit"
            onClick={handleReserveYourStay}
            disabled={!termsAgreed}
            id="update-reservation-button"
          >
            Update Reservation
          </Button>
          <button
            id="cancel-changes-button"
            onClick={navigateToReservationPage}
            className="mt-4 g-0 btn btn-link btn-sm"
          >
            Cancel Changes
          </button>
        </div>
      </Col>
      <DarkBackground show={isUpdatingReservation}>
        <LoadingOverlay
          active={isUpdatingReservation}
          spinner={true}
          text={overlayMessage}
        ></LoadingOverlay>
      </DarkBackground>
    </Row>
  );
};
