import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import Title3 from "../../global/Title3/Title3";
import { guestInfo } from "./SelectAddOns.module.scss";

const GuestInfo: React.FC<any> = (props) => {
  const guest = props.guestDetails;
  return (
    <div className={guestInfo}>
      <Title3 id={"guest-information"}>Guest Information</Title3>
      <div className="guestDetails">
        {props.isMember && guest.id && (
          <div id="member-id">
            <strong>ID:</strong> {guest.id}
          </div>
        )}
        <div id="user-name">
          <FontAwesomeIcon icon="user-friends" className="me-2 mt-2" />
          {guest.firstName + " " + guest.lastName}
        </div>
        <div id="email-address">
          <FontAwesomeIcon icon={faEnvelope} className="me-2 mt-2" />
          {guest.email}
        </div>
        {guest.phno !== "NA" && (
          <div id="phone-no">
            <FontAwesomeIcon icon={faPhoneAlt} className="me-2 mt-2" />
            {guest.phno}
          </div>
        )}
      </div>
    </div>
  );
};

export default GuestInfo;
