import * as React from "react";
import { Row } from "react-bootstrap";
import { container, styledRow, tooltip } from "./NewCurrentTotal.module.scss";

export const Container: React.FC<any> = (props) => {
  return (
    <div className={`${container} ${props.className}`}>{props.children}</div>
  );
};
export const StyledRow: React.FC<any> = (props) => {
  return (
    <Row className={`${props.className} ${styledRow} g-0`}>
      {props.children}
    </Row>
  );
};
export const Tooltip: React.FC<any> = React.forwardRef((props, ref) => {
  return (
    <span className={tooltip} {...props} ref={ref}>
      {props.children}
    </span>
  );
});

Tooltip.displayName = "Tooltip";
